//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { responsiveImg } from '~/plugins/responsive-img'
const ENTER_SITE_BUTTON = {
  th: 'เข้าสู่เว็บไซต์ตลาดหลักทรัพย์ (SET)',
  en: 'Enter SET Website'
}
export default {
  name: 'Welcome',
  props: {
    dataObj: {
      type: Object,
      default: Object
    }
  },
  data () {
    const { locale } = this.$i18n
    return {
      cntImage: null,
      remark: '',
      styleObjectBtn: {
        color: '',
        backgroundColor: '',
        backgroundColorHover: ''
      },
      enterSiteButtonTitle: ENTER_SITE_BUTTON[locale]
    }
  },
  computed: {
    content () {
      return this.dataObj.body || {}
    },
    info () {
      return this.dataObj.info || {}
    },
    advertisement () {
      if (this.content && this.content.type === 'Advertisement') {
        return true
      }
      return false
    },
    adsCode () {
      if (this.info.device === 'Desktop') {
        return this.content.adsCodeDesktop
      } else if (this.content.adsCodeMobile) {
        return this.content.adsCodeMobile
      } else {
        return null
      }
    }
  },
  mounted () {
    // init welcom page
    this.init()
    this.registerEvent()

    this.$nextTick(() => {
      this.setRedirectInSec()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    init () {
      if (this.advertisement === true && !this.adsCode) {
        window.location = this.localePath({ name: 'home' })
      }
      if (this.content) {
        this.styleObjectBtn.color = this.content.btnFontColor
        this.styleObjectBtn.backgroundColor = this.content.btnColor
        this.styleObjectBtn.backgroundColorHover = this.content.btnColor
        this.$refs.welcome.style.backgroundColor = this.content.bgColor
        this.remark = this.content.remark
        if (this.info.device === 'Desktop') {
          this.renderBgimg(this.content.bgImage)
          this.cntImage = this.content.cntImage ? this.content.cntImage.images : null
        } else if (this.info.device === 'Mobile' && (this.content.mobileBgImage !== null || this.content.mobileCntImage !== null)) {
          this.renderBgimg(this.content.mobileBgImage)
          this.cntImage = this.content.mobileCntImage ? this.content.mobileCntImage.images : null
        } else if (this.info.device === 'Mobile' && this.content.mobileBgImage === null && this.content.mobileCntImage === null) {
          this.renderBgimg(this.content.bgImage)
          this.cntImage = this.content.cntImage ? this.content.cntImage.images : null
        }
        this.addLinkAds()
      }
    },
    registerEvent () {
      window.addEventListener('resize', () => { this.init() })
    },
    removeEvent () {
      window.addEventListener('resize', () => { this.init() })
    },
    renderBgimg (bgImage) {
      const BgImgUrl = responsiveImg((bgImage && bgImage.images) || null)
      this.$refs.welcome.style.backgroundImage = `url('${BgImgUrl}')`
    },
    setRedirectInSec () {
      if (this.content.redirectInSec) {
        setTimeout(() => {
          window.location = this.localePath({ name: 'home' })
        }, (this.content.redirectInSec * 1000))
      }
    },
    addLinkAds () {
      if (this.content.imageClickLink && !this.advertisement) {
        const createA = document.createElement('a')
        createA.setAttribute('href', this.content.imageClickLink || '#')
        createA.setAttribute('class', 'stretched-link')
        createA.onclick = () => {
          this.$personalized.clickElement({
            pageName: 'Welcome Page',
            title: this.content.name,
            sectionName: 'Welcome Page - Banner'
          })
        }
        this.$refs.cntImage.appendChild(createA)
      }
    },
    addEventTracking () {
      this.$personalized.clickElement({
        pageName: 'Welcome Page',
        title: this.enterSiteButtonTitle,
        sectionName: 'Welcome Page - Button'
      })
    }
  }
}
