//
//
//
//

import { mapGetters } from 'vuex'
export default {
  layout: 'welcomepage',
  computed: {
    ...mapGetters({ content: 'contents/getContentData' })
  }
  // mounted () {
  //   this.$store.dispatch('contents/getContent', this.$route)
  // }
}
